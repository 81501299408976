import React from 'react';
import styled from 'styled-components';

const PageTitle = styled.h1`
  font-size: 30px;
  color: var(--theme-color);
  display: flex;
  align-items: center;

  &:before {
    content: '';
    border-left: 5px solid var(--theme-color);
    margin-right: 10px;
    border-radius: 10px;
    height: 23px;
    display: inline-block;
  }
  margin-bottom: 10px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: var(--theme-color);
  display: flex;
  align-items: center;

  &:before {
    content: '';
    border-left: 5px solid var(--theme-color);
    margin-right: 10px;
    border-radius: 10px;
    height: 23px;
    display: inline-block;
  }
  margin-bottom: 10px;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
`;

const FieldLabel = styled.div`
  font-size: 16px;
  margin-right: 10px;
  color: #484848;
`;

const FieldValue = styled.div`
  font-size: 16px;
`;

const SeperatorLine = styled.div`
  width: 100%;
  border: 1px solid #e9e9e9;
`;

export {
  PageTitle,
  SectionTitle,
  FieldRow,
  FieldLabel,
  FieldValue,
  SeperatorLine,
};
