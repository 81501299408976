import * as ShopeeActions from '../../Actions/Shopee';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const idListResp = await ShopeeActions.fetchProductList(
      {
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      },
      paging,
    );
    onSuccess({
      results: await ShopeeActions.fetchProductDetail(
        idListResp.results.map((r) => r.item_id),
      ),
      total: idListResp.total,
    });
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {title: 'item_id', key: 'item_id', dataIndex: 'item_id'},
  {title: 'item_status', key: 'item_status', dataIndex: 'item_status'},
  {title: 'item_name', key: 'item_name', dataIndex: 'item_name'},
  {title: 'category_id', key: 'category_id', dataIndex: 'category_id'},
  {title: 'condition', key: 'condition', dataIndex: 'condition'},
];

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
