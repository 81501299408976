import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from '../Utils/ApiUtils';

const UserOutlet = getOutlet('user');

async function fetchProductList(query = {}, paging) {
  const {
    update_time_from = 0,
    update_time_to = 0,
    item_status = ['NORMAL'],
  } = query;
  const resp = await req(
    `${Config.apiHost}/shopee/product/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        offset: paging.offset,
        page_size: paging.limit,
        update_time_from,
        update_time_to,
        item_status,
      },
    },
  );

  if (resp.error) {
    throw new Error(resp);
  }

  return {
    total: resp.response.total_count,
    results: resp.response.item,
  };
}

async function fetchProductDetail(item_id_list) {
  const resp = await req(
    `${Config.apiHost}/shopee/product/detail?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        item_id_list,
      },
    },
  );

  if (resp.error) {
    throw new Error(resp);
  }

  return resp.response.item_list;
}

async function uploadImage(formData) {
  // NOTICE: we use fetch directly here
  const resp = await fetch(
    `${Config.apiHost}/shopee/upload/image?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      body: formData,
    },
  );

  return (await resp.json()).response.image_info.image_id;
}

async function fetchCategoryList() {
  return req(
    `${Config.apiHost}/shopee/product/category/get?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );
}

async function fetchLogisticList() {
  return req(
    `${Config.apiHost}/shopee/logistics/channel/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );
}

async function createProduct(data) {
  return req(
    `${Config.apiHost}/shopee/add/item?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data,
    },
  );
}

export {
  fetchProductList,
  fetchProductDetail,
  uploadImage,
  fetchCategoryList,
  fetchLogisticList,
  createProduct,
};
